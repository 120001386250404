import { location, service_category } from '@/types/graphql';
import { log } from 'console';
import { DsxPlaceholder, MenuItem, NavItem, SitemapItem } from "@/utils/types";
import { DsxImageType } from "@/utils/types";
import { getImage, getIconByTag } from "@/utils/api";
import { image, icon, shop, service, nav, landing, make, make_category } from '@/types/graphql';
import { getMapLink } from '@/utils/maps';
import { getShemaWorkHoursShort } from '@/utils/date';
import { getShemaWorkHoursFull } from '@/utils/date';
import { gql } from '@/utils/api';
import { resolve as urlResolve } from 'url';

export const getPlaceholders = async () =>
{
	let placeholders: DsxPlaceholder[] = [];
	try
	{
		const data = await fetch(`${process.env.DSX_API_HOST_URL}/data/placeholders/${process.env.SHOP_ID}`, {
			method: "GET",
			headers: {
				'X-DSX-Auth-Token': `${process.env.DSX_API_TOKEN}`,
				'Content-Type': 'application/json; charset=UTF-8',
			}, next: { revalidate: +(process.env.NEXT_REVALIDATE ?? 1) }
		});

		// let txtResponse = await data?.text();
		// placeholders = JSON.parse(txtResponse);
		placeholders = await data?.json();

	} catch (e)
	{
		console.log(e)
	}

	return placeholders;
}

export const escapeRegExp = (s: string) =>
{
	return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const replaceAllCaseInsensitive = (str: string, search: string, replacement: string) =>
{
	const escapedSearch = escapeRegExp(search);
	const searchRegExp = new RegExp(escapedSearch, 'gi');
	return str.replace(searchRegExp, replacement);
}

export const replacePlaceholders = async (text: string) =>
{
	const placeholders: DsxPlaceholder[] = await getPlaceholders();

	for (const placeholder of placeholders)
	{
		// text = text.replaceAll(placeholder.code ?? "", placeholder.value ?? "");
		text = replaceAllCaseInsensitive(text, placeholder.code ?? "", placeholder.value ?? "");
	}

	return text;
}

export const processDynamicLink = (text: string): string =>
{
	// text = text.replace("#make-appointment", "javascript:{if(apnt){window.location.hash='make-appointment';apnt.start();}}");
	// text = text.replace("#make-appointment", "#make-appointment-" + v4());

	return text;
}

export const joinDomainAndPath = (domain: string | undefined, path: string) =>
{
	if (typeof domain !== 'string' || typeof path !== 'string')
	{
		domain = '';
	}
	if (!domain.endsWith('/'))
	{
		domain += '/';
	}

	return urlResolve(domain, path);
}


export const capitalizeFirstLetters = (str: string) =>
{
	return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const getImageUrl = (s: string | null) =>
{
	if (s)
	{
		return `${process.env.NEXT_PUBLIC_IMAGE_HOST_URL}/${s}`;
	} else
	{
		return "/images/p.webp";
	}
}

export const getImageBlurHash = (s: string | null) =>
{
	return s ?? process.env.DEFAULT_BLURHASH;
}

export const getDsxImage = async (dsxImage: DsxImageType) =>
{

	let data: any = dsxImage.data
	let value: any = dsxImage.value
	let priority: boolean | undefined = dsxImage.priority
	let alt: string | undefined = dsxImage.alt
	let src: string | undefined = dsxImage.src
	let className: string | undefined = dsxImage.className
	let iconClassName: string | undefined = dsxImage.iconClassName
	let fill: boolean | undefined = dsxImage.fill
	let useIcon: boolean | undefined = dsxImage.useIcon

	if (typeof data == 'string' && !value)
	{
		const nImage: Partial<image> = {};
		if (data?.startsWith('/'))
		{
			nImage.type = 1;
			value = data;
		} else
		{
			let imgId: any = parseInt(data);
			if (!isNaN(imgId) && imgId > 0)
			{
				nImage.type = 1;
				value = imgId;
			} else
			{
				nImage.type = 2;
				nImage.tag = data;
			}

		}
		data = nImage;
	}

	let tmpData: any = value;
	if (typeof data == 'number')
	{
		tmpData = data;
	}

	const dataObj = { ...data }

	let ico: icon | null | undefined = null;
	let img: Partial<image> | null | undefined = null;
	let _src: string = '/images/p.webp';

	if (tmpData)
	{
		const imageId = parseInt(tmpData);

		if (!isNaN(imageId) && imageId > 0)
		{
			dataObj.image_id = tmpData;
		} else
		{
			if (tmpData.startsWith('/') || tmpData.startsWith('http://') || tmpData.startsWith('https://'))
			{
				dataObj.value = tmpData;
			} else
			{
				ico = await getIconByTag(tmpData);
			}
		}

	}

	let iconType = '';
	let iconName = '';

	if (useIcon !== false)
	{
		useIcon = true;
	}

	if (src)
	{
		_src = src;
	}
	if (dataObj.value)
	{
		_src = dataObj.value;
	}

	if (_src?.startsWith('/') && _src != '/images/p.webp')
	{
		_src = `${process.env.NEXT_PUBLIC_IMAGE_HOST_URL}${_src}`;
	}

	let _alt: string = alt ?? "";

	let _class = className;

	if (dataObj.image_id)
	{
		img = await getImage(dataObj.image_id);
	}

	if (!img && ico)
	{
		img = {
			type: 2,
			src: ico.src,
			tag: ico.tag,
			library: ico.library
		}
	}

	if (img)
	{
		if (img.alt_text)
		{
			_alt = img.alt_text;
		}

		if (img.type == 1)
		{
			useIcon = false;
			if (img.webp)
			{
				_src = `${process.env.NEXT_PUBLIC_IMAGE_HOST_URL}/${img.webp}`;
			}
		} else
		{
			if (iconClassName)
			{
				_class = iconClassName;
			}

			if (img.src)
			{
				_src = `${process.env.NEXT_PUBLIC_IMAGE_HOST_URL}/${img.src}`;
			}

			if (img.tag && img.library == 'fa')
			{
				const tagParts: string[] = img.tag.split(' ');
				if (tagParts && tagParts.length == 2)
				{
					iconType = tagParts[0].replace('fa-', '');
					iconName = tagParts[1]
				}
			}
		}
	}

	const ds: DsxImageType = { type: (img?.type ?? 1), data: dsxImage.data, value: dsxImage.value, priority: (priority ?? false), alt: _alt, src: _src, className: (className ?? ""), iconClassName: dsxImage.iconClassName, fill: (fill ?? true), useIcon: useIcon, iconType: iconType, iconName: iconName, class: _class };
	return ds;
}


export const getDsxShopSchema = async (shop: shop | null | undefined) =>
{
	if (!shop)
	{
		return "";
	}

	let content = '';

	const areas: string[] = [];
	if (shop?.info?.city)
	{
		areas.push(shop.info?.city);
	}
	if (shop?.info?.county)
	{
		areas.push(shop.info?.county);
	}
	if (shop?.locations && shop.locations.length > 0)
	{
		shop.locations.map((loc) =>
		{
			if (loc.city_state && !areas.includes(loc.city_state))
			{
				areas.push(loc.city_state);
			}
		})
	}

	const dsxLogoImage: DsxImageType = { fill: true, value: shop?.info?.logo_image_id, data: shop?.info?.logo_image_id, alt: '', iconClassName: "", priority: false }
	const logoImage: DsxImageType = await getDsxImage(dsxLogoImage);

	const dsxMainImage: DsxImageType = { fill: true, value: shop?.info?.main_image_id, data: shop?.info?.main_image_id, alt: '', iconClassName: "", priority: false }
	const mainImage: DsxImageType = await getDsxImage(dsxMainImage);

	const mapLink = getMapLink(shop);

	if (shop)
	{
		content = `{
			"@context": "https://schema.org",
			"@type": "AutoRepair",
			${shop.work_hours && shop.work_hours.length > 0 ? `"openingHours": "${getShemaWorkHoursShort(shop.work_hours)}",` : ''}
			"address": {
			  "@type": "PostalAddress",
			  "addressLocality": "${shop.info?.city ?? ''}",
			  "addressRegion": "${shop.info?.state ?? ''}",
			  "postalCode": "${shop.info?.zip ?? ''}",
			  "streetAddress": "${shop.info?.address ?? ''}"
			},
			"aggregateRating": {
			  "@type": "AggregateRating",
			  "ratingValue": 5,
			  "reviewCount": 1646
			},
			"areaServed": [
			  "${areas.join('", "')}"
			],
			"legalName": "${shop.info?.name ?? ''}",
			"name": "${shop.info?.name ?? ''}",
			"location": {
			  "@type": "Place",
			  "address": {
				"@type": "PostalAddress",
				"addressLocality": "${shop.info?.city ?? ''}",
				"addressRegion": "${shop.info?.state ?? ''}",
				"postalCode": "${shop.info?.zip ?? ''}",
				"streetAddress": "${shop.info?.address ?? ''}"
			  }
			},
			"logo": "${logoImage.src}",
			"image": "${mainImage.src}",
			"slogan": "${shop.info?.primary_slogan ?? ''}",
			"telephone": "${shop.info?.phone_number ?? ''}",
			"geo": {
			  "@type": "GeoCoordinates",
			  "latitude": ${shop.info?.lat ?? '0'},
			  "longitude": ${shop.info?.lng ?? '0'}
			},
			"hasMap": "${mapLink}",
			${shop.work_hours && shop.work_hours.length > 0 ? `"openingHoursSpecification": [${getShemaWorkHoursFull(shop.work_hours)}],` : ''}
			"url": "${shop.info?.website_url ?? ''}"
		  }`
	}

	return content;

}

export const getServiceNameBySlug = async (slug: string): Promise<string> =>
{
	const json = await gql(JSON.stringify({
		"query": `query ($id: Int, $slug: String) {
			
			shop(where: {id: {_eq: $id}}) {
				services(where: {slug: {_eq: $slug}}) {
					id
					name
					service {
						name
					}
				}
			}
			
		}`, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0"), "slug": slug }
	}));

	let data: string = '';

	if (json['data'] && json['data']['shop'][0] && json['data']['shop'][0]['services'] && json['data']['shop'][0]['services'][0])
	{
		data = json['data']['shop'][0]['services'][0].name ?? json['data']['shop'][0]['services'][0].service?.name;
	}

	return data;

}

export const getMakeNameBySlug = async (slug: string): Promise<string> =>
{
	const json = await gql(JSON.stringify({
		"query": `query ($id: Int, $slug: String) {
			
			shop(where: {id: {_eq: $id}}) {
				makes(where: {slug: {_eq: $slug}}) {
					id
					name
					make {
						name
					}
				}
			}
			
		}`, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0"), "slug": slug }
	}));

	let data: string = '';

	if (json['data'] && json['data']['shop'][0] && json['data']['shop'][0]['makes'] && json['data']['shop'][0]['makes'][0])
	{
		data = json['data']['shop'][0]['makes'][0].name ?? json['data']['shop'][0]['makes'][0].make?.name;
	}

	return data;

}

export const getServiceVideoNameBySlug = async (slug: string): Promise<string> =>
{
	const json = await gql(JSON.stringify({
		"query": `query ($id: Int, $slug: String) {
			
			shop(where: {id: {_eq: $id}}) {
				service_videos(where: {slug: {_eq: $slug}}) {
					id
					name
					service_video {
						name
					}
				}
			}
			
		}`, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0"), "slug": slug }
	}));

	let data: string = '';

	if (json['data'] && json['data']['shop'][0] && json['data']['shop'][0]['service_videos'] && json['data']['shop'][0]['service_videos'][0])
	{
		data = json['data']['shop'][0]['service_videos'][0].name ?? json['data']['shop'][0]['service_videos'][0].service_video?.name;
	}

	return data;

}

export const getBlogPostNameBySlug = async (slug: string): Promise<string> =>
{
	const json = await gql(JSON.stringify({
		"query": `query ($id: Int, $slug: String) {
			blog_post(where: {shop_id: {_eq: $id}, slug: {_eq: $slug }}) {
				title
			}
		}`, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0"), "slug": slug }
	}));

	let data: string = '';

	if (json['data'] && json['data']['blog_post'] && json['data']['blog_post'][0] && json['data']['blog_post'][0].title)
	{
		data = json['data']['blog_post'][0].title;
	}

	return data;

}

export const getLandingNameBySlug = async (slug: string): Promise<string> =>
{
	const json = await gql(JSON.stringify({
		"query": `query ($id: Int, $slug: String) {
			shop(where: {id: {_eq: $id}}) {
				landings(where: {slug: {_eq: $slug}}) {
					title
				}
			}
		}`, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0"), "slug": slug }
	}));

	let data: string = '';

	if (json['data'] && json['data']['shop'][0] && json['data']['shop'][0]['landings'] && json['data']['shop'][0]['landings'][0] && json['data']['shop'][0]['landings'][0].title)
	{
		data = json['data']['shop'][0]['landings'][0].title;
	}

	return data;

}

export const getLocationNameBySlug = async (slug: string): Promise<string> =>
{

	const json = await gql(JSON.stringify({
		"query": `query ($id: Int, $slug: String) {
			shop(where: {id: {_eq: $id}}) {
				locations(where: {slug: {_eq: $slug}}) {
					title
				}
			}
		}`, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0"), "slug": slug }
	}));

	let data: string = '';

	if (json['data'] && json['data']['shop'][0] && json['data']['shop'][0]['locations'] && json['data']['shop'][0]['locations'][0] && json['data']['shop'][0]['locations'][0].title)
	{
		data = json['data']['shop'][0]['locations'][0].title;
	}

	return data;

}

export const getSitemapItems = async (): Promise<SitemapItem[]> =>
{

	const json = await gql(JSON.stringify({
		"query": `query ($id: Int) {
			shop(where: {id: {_eq: $id}}) {
			  info {
				id
				website_url
				blog_enabled
				blog_title
				employment_enabled
				employment_title
				faq_enabled
				faq_title
				name
				slug
				staff_enabled
				staff_title
				updated_at
				location_landing_pages_enabled
			  }
			  blog_posts(where: {enabled: {_eq: true}}) {
				id
				slug
				title
				updated_at
			  }
			  landings(where: {enabled: {_eq: true}}) {
				id
				name
				updated_at
				slug
			  }
			  locations(where: {enabled: {_eq: true}}) {
				id
				slug
				title
				updated_at
			  }
			  make_categories(where: {enabled: {_eq: true}}) {
				id
				slug
				name
				makes(where: {enabled: {_eq: true}}) {
				  id
				  slug
				  name
				  make {
					name
				  }
				  updated_at
				}
			  }
			  service_categories(where: {enabled: {_eq: true}}) {
				id
				name
				slug
				updated_at
				services(where: {enabled: {_eq: true}}) {
				  id
				  name
				  slug
				  service {
					name
				  }
				  updated_at
				}
			  }
			  service_video_categories(where: {enabled: {_eq: true}}) {
				name
				service_videos(where: {enabled: {_eq: true}}) {
				  name
				  slug
				  service_video {
					name
				  }
				  updated_at
				}
			  }
			  shops {
				id
				info {
				  name
				  slug
				}
			  }
			  updated_at
			  setting {
				is_multi_location
			  }
			}
		  }
		  `, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0") }
	}));

	let shop: Partial<shop> = {};

	if (json['data'] && json['data']['shop'][0] && json['data']['shop'][0])
	{
		shop = json['data']['shop'][0];
	}

	const items: SitemapItem[] = [];

	if (!shop)
	{
		return items;
	}

	let url = shop.info?.website_url ?? '';

	if (!url)
	{
		return items;
	}

	if (!url.endsWith('/'))
	{
		url += '/';
	}

	items.push({
		name: "Home Page", loc: url, lastmod: shop.updated_at, changefreq: 'weekly', priority: 1
	});

	const aboutUsItems: SitemapItem[] = [];
	aboutUsItems.push({
		name: "Contact Us", loc: `${url}contact`, lastmod: shop.updated_at, changefreq: 'monthly', priority: 0.5
	});

	const digitalInspectionsItems: SitemapItem[] = [];
	digitalInspectionsItems.push({
		name: "We Are The Digital Shop", loc: `${url}we-are-the-digital-shop`, lastmod: shop.updated_at, changefreq: 'monthly', priority: 0.2
	});
	digitalInspectionsItems.push({
		name: "What is SmartFlow?", loc: `${url}what-is-smartflow`, lastmod: shop.updated_at, changefreq: 'monthly', priority: 0.2
	});

	aboutUsItems.push({
		name: "Digital Inspections", loc: `${url}digital-inspections`, lastmod: shop.updated_at, changefreq: 'monthly', priority: 0.2, items: digitalInspectionsItems
	});

	aboutUsItems.push({
		name: "Reviews", loc: `${url}reviews`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.9
	});

	aboutUsItems.push({
		name: "Specials", loc: `${url}specials`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.9
	});

	if (shop.info?.blog_enabled)
	{

		const blogPages: SitemapItem[] = [];

		if (shop.blog_posts && shop.blog_posts.length > 0)
		{
			for (const blogPost of shop.blog_posts)
			{
				blogPages.push({
					name: blogPost?.title ?? '', loc: `${url}blog/${blogPost?.slug}`, lastmod: blogPost?.updated_at, changefreq: 'weekly', priority: 0.7
				});
			}
		}

		aboutUsItems.push({
			name: "Blog", loc: `${url}blog`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.9, items: blogPages
		});
	}

	if (shop.info?.staff_enabled)
	{
		aboutUsItems.push({
			name: "Our Team", loc: `${url}team`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.8
		});
	}

	if (shop.info?.employment_enabled)
	{
		aboutUsItems.push({
			name: "Employment", loc: `${url}employment`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.8
		});
	}

	if (shop.info?.faq_enabled)
	{
		aboutUsItems.push({
			name: "FAQ", loc: `${url}faq`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.8
		});
	}

	if (shop.landings && shop.landings.length > 0)
	{
		for (const landing of shop.landings)
		{
			aboutUsItems.push({
				name: landing?.name ?? '', loc: `${url}about/${landing?.slug}`, lastmod: landing?.updated_at, changefreq: 'weekly', priority: 0.7
			});
		}
	}

	if (shop.info?.location_landing_pages_enabled && shop.locations && shop.locations.length > 0)
	{
		for (const location of shop.locations)
		{
			aboutUsItems.push({
				name: location?.title ?? '', loc: `${url}location/${location?.slug}`, lastmod: location?.updated_at, changefreq: 'weekly', priority: 0.7
			});
		}
	}

	if (shop.setting?.is_multi_location)
	{
		const shopLocationPage: SitemapItem[] = [];
		if (shop.shops && shop.shops.length > 0)
		{
			for (const locationShop of shop.shops)
			{
				if (locationShop?.id != shop.id)
				{
					shopLocationPage.push({
						name: locationShop?.info?.name ?? '', loc: `${url}locations/${locationShop?.info?.slug}`, lastmod: locationShop?.updated_at, changefreq: 'weekly', priority: 0.9
					});
				}
			}
		}

		aboutUsItems.push({
			name: "Locations", loc: `${url}locations`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.8, items: shopLocationPage
		});

	}

	items.push({
		name: "About Us", loc: `${url}about`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.8, items: aboutUsItems
	});

	const servicesPage: SitemapItem[] = [];
	if (shop.service_categories && shop.service_categories.length > 0)
	{
		for (const serviceCategory of shop.service_categories)
		{
			if (serviceCategory?.services && serviceCategory?.services.length > 0)
			{
				for (const service of serviceCategory?.services)
				{
					servicesPage.push({
						name: service?.name ?? (service?.service?.name ?? ''), loc: `${url}services/${service?.slug}`, lastmod: service?.updated_at, changefreq: 'monthly', priority: 0.6
					});
				}
			}
		}
	}
	items.push({
		name: "Services", loc: `${url}services`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.8, items: servicesPage
	});

	const makesPage: SitemapItem[] = [];
	if (shop.make_categories && shop.make_categories.length > 0)
	{
		for (const makeCategory of shop.make_categories)
		{
			if (makeCategory?.makes && makeCategory?.makes?.length > 0)
			{
				for (const make of makeCategory?.makes)
				{
					makesPage.push({
						name: make?.name ?? (make?.make?.name ?? ''), loc: `${url}vehicles-we-service/${make?.slug}`, lastmod: make?.updated_at, changefreq: 'monthly', priority: 0.6
					});
				}
			}
		}
	}
	items.push({
		name: "Vehicles We Service", loc: `${url}vehicles-we-service`, lastmod: shop.updated_at, changefreq: 'monthly', priority: 0.6, items: makesPage
	});

	const videosPage: SitemapItem[] = [];
	if (shop.service_video_categories && shop.service_video_categories.length > 0)
	{
		for (const serviceVideoCategory of shop.service_video_categories)
		{
			if (serviceVideoCategory.service_videos && serviceVideoCategory.service_videos.length > 0)
			{
				for (const serviceVideo of serviceVideoCategory.service_videos)
				{
					videosPage.push({
						name: serviceVideo?.name ?? (serviceVideo?.service_video?.name ?? ''), loc: `${url}service-videos/${serviceVideo?.slug}`, lastmod: serviceVideo?.updated_at, changefreq: 'monthly', priority: 0.5
					});
				}
			}
		}
	}
	items.push({
		name: "Service Videos", loc: `${url}service-videos`, lastmod: shop.updated_at, changefreq: 'monthly', priority: 0.5, items: videosPage
	});


	items.push({
		name: "Sitemap", loc: `${url}sitemap`, lastmod: shop.updated_at, changefreq: 'weekly', priority: 0.9
	});


	return items;

}


export const getMenuItems = async (): Promise<MenuItem[]> =>
{
	const jsonSettings = await gql(JSON.stringify({
		"query": `query ($id: Int) {
				shop(where: {id: {_eq: $id}}) {
				  info {
					id
					custom_menu_enabled
				  }
				}
			  }
			  `, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0") }
	}));

	let dataSettings: Partial<shop> = {};

	if (jsonSettings['data'] && jsonSettings['data']['shop'][0] && jsonSettings['data']['shop'][0])
	{
		dataSettings = jsonSettings['data']['shop'][0];
	}

	if (dataSettings && dataSettings.info?.custom_menu_enabled)
	{
		const json = await gql(JSON.stringify({
			"query": `query GetCustomMenu($id: Int) {
				nav(where: {_and: {shop_id: {_eq: $id}, enabled: {_eq: true}, parent_id: {_is_null: true}}}, order_by: {sort: asc}) {
				  is_new_page
				  parent_id
				  shop_id
				  sort
				  title
				  url
				  navs(order_by: {sort: asc}, where: {enabled: {_eq: true}}) {
					is_new_page
					shop_id
					sort
					title
					url
					navs(order_by: {sort: asc}, where: {enabled: {_eq: true}}) {
					  is_new_page
					  shop_id
					  sort
					  title
					  url
					  navs(order_by: {sort: asc}, where: {enabled: {_eq: true}}) {
						is_new_page
						shop_id
						sort
						title
						url
						navs(order_by: {sort: asc}, where: {enabled: {_eq: true}}) {
						  is_new_page
						  shop_id
						  sort
						  title
						  url
						  navs(order_by: {sort: asc}, where: {enabled: {_eq: true}}) {
							is_new_page
							shop_id
							sort
							title
							url
						  }
						}
					  }
					}
				  }
				}
			  }						
			  `, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0") }
		}));

		let data: nav[] = [];


		if (json['data'] && json['data']['nav'])
		{
			data = json['data']['nav'];
		}

		const getNavItem = function (d: nav[] | undefined): MenuItem[]
		{

			if (d && d.length)
			{
				const n: MenuItem[] = [];
				for (let i = 0; i < d.length; i++)
				{
					const item = d[i];
					const navs = getNavItem(item.navs);
					n.push({
						title: item.title ?? '',
						path: item.url ?? '',
						isNewPage: item.is_new_page ?? false,
						isDropdown: (navs && navs.length) ? true : false,
						dropNavs: navs
					});
				}

				return n;
			}

			return [];
		}

		if (data)
		{

			const navigation: MenuItem[] = getNavItem(data);

			return navigation;
		}

	} else
	{

		const json = await gql(JSON.stringify({
			"query": `query ($id: Int) {
				shop(where: {id: {_eq: $id}}) {
				  info {
						id
						website_url
						blog_enabled
						blog_title
						employment_enabled
						employment_title
						faq_enabled
						faq_title
						name
						slug
						staff_enabled
						special_page_enabled
						staff_title
						updated_at
						location_landing_pages_enabled
				  }
				  blog_posts(where: {enabled: {_eq: true}}) {
						id
						slug
						title
						updated_at
				  }
				  landings(where: {enabled: {_eq: true}}) {
						id
						name
						updated_at
						slug
				  }
				  locations(where: {enabled: {_eq: true}}) {
						enabled
						id
						slug
						title
						updated_at
				  }
				  make_categories(where: {enabled: {_eq: true}}) {
						id
						slug
						name
						make_category{
							name
						}
						makes(where: {enabled: {_eq: true}}) {
							id
							slug
							name
							updated_at
							make {
								name
							}
						}
				  }
				  service_categories(where: {enabled: {_eq: true}}) {
						id
						name
						slug
						updated_at
						service_category{
							name
						}
						services(where: {enabled: {_eq: true}}) {
							id
							name
							slug
							updated_at
							service {
								name
							}
						}
				  }
				  service_video_categories(where: {enabled: {_eq: true}}) {
						name
						service_videos(where: {enabled: {_eq: true}}) {
							name
							slug
							updated_at
							service_video {
								name
							}
						}
				  }
				  shops {
						id
						info {
							name
							slug
						}
				  }
				  updated_at
				  setting {
						is_multi_location
				  }
				}
			}
			`, "variables": { "id": parseInt(process.env.SHOP_ID ?? "0") }
		}));

		let data: Partial<shop> = {};

		if (json['data'] && json['data']['shop'][0] && json['data']['shop'][0])
		{
			data = json['data']['shop'][0];
		}

		const getNavItem = function (d: { title: string, path: string, navs?: any[] }[] | undefined): MenuItem[]
		{
			if (!d || d.length === 0) return [];

			const n: MenuItem[] = [];
			for (let i = 0; i < d.length; i++)
			{
				const item = d[i];
				const navs = getNavItem(item.navs);
				n.push({
					title: item.title,
					path: item.path,
					isDropdown: navs.length > 0 ? true : false,
					dropNavs: navs.length > 0 ? navs : undefined
				});
			}
			return n;
		}

		// Use the getNavItem function to convert existing dropdown structures

		const dropdownMakes = getNavItem(data?.make_categories
			?.filter(_ => (
				_?.name || _?.make_category?.name) &&
				_?.makes?.some(_ => _?.name || _?.make?.name))
			?.map((_: make_category) => ({
				title: (_?.name || _?.make_category?.name || ''),
				path: '',
				navs: _?.makes?.map((_: make) => ({
					title: _?.name || _?.make?.name,
					path: `/vehicles-we-service/${_?.slug || _?.make?.slug}`
				}))
			})));

		const dropdownServices = getNavItem(data?.service_categories
			?.filter(_ => (
				_?.name || _?.service_category?.name) &&
				_?.services?.some(_ => _?.name || _?.service?.name))
			?.map((_: service_category) => ({
				title: (_?.name || _?.service_category?.name || ''),
				path: '',
				navs: _?.services?.map((service: service) => ({
					title: service?.name || service?.service?.name,
					path: `/services/${service?.slug || service?.service?.slug}`
				}))
			})));

		const dropdownAbout = getNavItem([
			{
				title: 'About',
				path: '/about',
				navs: [
					{ title: 'About Us', path: '/about' },
					{ title: 'Reviews', path: '/reviews' },

					data?.info?.blog_enabled === true &&
					{ title: 'Our Blog', path: '/blog' },

					data?.info?.special_page_enabled === true &&
					{ title: 'Specials', path: '/specials' },

					data?.info?.staff_enabled === true &&
					{ title: 'Our Team', path: '/team' },

					data?.info?.employment_enabled === true &&
					{ title: 'Employment', path: '/employment' },

					data?.info?.faq_enabled === true &&
					{ title: 'FAQ', path: '/faq' },

					{ title: 'Contact', path: '/contact' },
				]
			},
			{
				title: 'We Are The Digital Shop',
				path: '',
				navs: [
					{ title: 'We Are The Digital Shop', path: '/we-are-the-digital-shop' },
					{ title: 'What is SmartFlow?', path: '/what-is-smartflow' },
					{ title: 'Digital Inspections', path: '/digital-inspections' }
				]
			}
		]);

		// Populate locations if they exist
		if (data?.locations?.length &&
			data?.locations?.some(_ => _?.enabled && _?.title))
		{
			dropdownAbout.push({
				title: 'Locations',
				path: '',
				dropNavs: getNavItem(data?.locations?.map((location: location) => ({
					title: location?.city_state || location?.title || '',
					path: `/location/${location?.slug}`,
					navs: []
				})))
			});
		}

		// Populate landings if they exist
		if (data?.landings?.length &&
			data?.locations?.some(_ => _?.enabled && _?.title))
		{
			dropdownAbout.map((menuItem) =>
			{
				menuItem?.title === 'About' &&
					data?.landings?.map((landing: landing) => (
						menuItem?.dropNavs && menuItem?.dropNavs.push({
							title: landing?.name ?? '',
							path: `/about/${landing?.slug}`,
							isDropdown: false,
							dropNavs: []
						})))
			});
		}

		const navigation: MenuItem[] = [
			{ title: "Home", path: "/" },
			{ title: "Vehicles We Service", path: "/vehicles-we-service", isDropdown: true, dropNavs: dropdownMakes },
			{ title: "Services", path: "/services", isDropdown: true, dropNavs: dropdownServices },
			{ title: "Service Videos", path: "/service-videos" },
			{ title: "About", path: "/about", isDropdown: true, dropNavs: dropdownAbout },
			{ title: "Contact", path: "/contact" }
		];

		return navigation;

	}

	return [];
}