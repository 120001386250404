"use client";
import { FormEvent } from 'react'
import FAIcon from "@/base-components/icons";
import React, { useMemo } from 'react';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import Button, { ButtonSize, ButtonStyle, ButtonType } from "./button";

export default function FormContact({ title = '', formClass = 'mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2', labelClass = 'mb-1 inline-block text-sm text-neutral-800', inputClass = 'w-full rounded border border-neutral-300 bg-neutral-50 px-3 py-2 text-neutral-800 outline-none ring-primary-300 transition duration-100 focus:ring', submitButtonColor = 'primary', submitButtonTint = 500, divClass = 'sm:col-span-2', buttonDivClass = 'flex items-center justify-between sm:col-span-2', titleClass = 'font-bold' }: { title: string, formClass: string, labelClass: string, inputClass: string, submitButtonColor: string, submitButtonTint: number, divClass: string, buttonDivClass: string, titleClass: string })
{

	type NotificationType = 'success' | 'error';

	const [api, contextHolder] = notification.useNotification();
	type NotificationPlacement = NotificationArgsProps['placement'];
	const Context = React.createContext({ name: 'Default' });

	const openNotification = (placement: NotificationPlacement, message: string, type: NotificationType) =>
	{
		const capitalized = type.charAt(0).toUpperCase() + type.slice(1);
		api[type]({
			message: capitalized,
			description: <Context.Consumer>{() => message}</Context.Consumer>,
			placement,
		});
	};
	const contextValue = useMemo(() => ({ name: 'Contact Form' }), []);

	async function onSubmit(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault()

		const formData = new FormData(event.currentTarget)
		const response = await fetch('https://services-dev.autovitals.com/Contact.asmx/SubmitContactRequest?' + (new URLSearchParams(formData as any).toString()), {
			method: 'GET'
		})

		let txtResponse = await response?.text();
		if (txtResponse?.startsWith('harry({'))
		{
			txtResponse = txtResponse?.replace('harry({', '{')
		}
		if (txtResponse?.endsWith('});'))
		{
			txtResponse = txtResponse?.replace('});', '}')
		}

		const json = JSON.parse(txtResponse);
		if (json && json.status && json.message)
		{
			openNotification('bottomRight', json.message, json.status);
		}
	}

	return (
		<>
			<form className={formClass} onSubmit={onSubmit}>

				{
					title && title?.length > 0 &&
					<h2 className={titleClass}>
						{title}
					</h2>
				}

				<div>
					<label htmlFor="firstName" className={labelClass}>First name*</label>
					<input required id="firstName" name="firstName" autoComplete="given-name" className={inputClass} />
				</div>

				<div>
					<label htmlFor="lastName" className={labelClass}>Last name*</label>
					<input required id="lastName" name="lastName" autoComplete="family-name" className={inputClass} />
				</div>

				<div className={divClass}>
					<label htmlFor="email" className={labelClass}>Email</label>
					<input id="email" name="email" autoComplete="email" className={inputClass} />
				</div>

				<div className={divClass}>
					<label htmlFor="phone" className={labelClass}>Phone*</label>
					<input required id="phone" name="phone" autoComplete="tel" className={inputClass} />
				</div>

				<div className={divClass}>
					<label htmlFor="comment" className={labelClass}>Message*</label>
					<textarea required id="comment" name="comment" autoComplete="off" className={`${inputClass} h-56`}></textarea>
				</div>

				<div className={buttonDivClass}>

					<input type="hidden" name="shopId" value={process.env.SHOP_ID} />
					{/* <input type="hidden" name="shopId" value={10066} /> */}
					<input type="hidden" name="cb" value="harry" />
					<input type="hidden" name="gRecaptchaResponse" value="dummy" />

					<Context.Provider value={contextValue}>
						{contextHolder}

						<Button
							id={'form-submit-button'}
							type={ButtonType.submit}
							style={ButtonStyle.filled}
							color={submitButtonColor}
							tint={submitButtonTint}>

							Send
							<FAIcon type="solid" nameIco='fa-arrow-right' customClass="h-4 w-4 ml-2" />

						</Button>

						{/* <button type="submit" className={submitButtonClass}>
							Send
							<FAIcon type="solid" nameIco='fa-arrow-right' customClass="h-4 text-white ml-2" />
						</button> */}

					</Context.Provider>

					<small className="text-neutral-600">*Required</small>

				</div>

			</form>
		</>
	)
}